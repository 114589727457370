body {
  margin: 0;
  font-family: 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  height: 100vh;
  align-items: flex-end;
}

.image-container img {
  height: 90vh;
  object-fit: cover;
  cursor: pointer;
}

.button {
  color: #0000ff;
  padding: 0px;
  background-color: white;
  cursor: pointer;
  font-size: 22px;
}

.button:hover {
  text-decoration: underline;
}

.text {
  color: #0000ff;
  font-size: 22px;
  /* max-width: calc(50vw - 15px); */
  --white-space: calc(calc(100vw - calc(90vh * 0.7)) / 2 - 15px);
  --adjusted-text-space: calc(max(var(--white-space), 320px));
  --default-text-space: calc(50vw - 15px);
  max-width: calc(min(var(--default-text-space), var(--adjusted-text-space)));
  max-height: calc(100vh - 20px - 25px);
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.text::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.text {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.highlight {
  background-color: white;
}

.top {
  position: absolute;
  top: 10px;
}

.right {
  position: absolute;
  right: 10px;
  text-align: right;
}

.bottom {
  position: absolute;
  bottom: 10px;
}

.left {
  position: absolute;
  left: 10px;
}

/* smaller font for mobile devices */
@media only screen and (max-width: 768px) {
  .button {
    font-size: 16px;
  }
  .text {
    font-size: 16px;
    --adjusted-text-space: calc(max(var(--white-space), 260px));
  }
}
